<template>
  <div class="container">
    <div class="group-title d-flex panel-body">
      {{ $getWording("LBL_DASH_IR_PANEL_TITLE") }}
    </div>
    <div id="chart">
      <apexchart
        width="1100"
        height="175"
        type="heatmap"
        :options="chartOptions"
        :series="series"
        @updated="onInfraredChartRenderDone"
      ></apexchart>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/styles/common.scss";

.group-title {
  margin: 15px 15px 15px 0;
  font-size: 1.35em;
  font-weight: bold;
  text-align: left;
}

.setting-ui-label {
  min-width: 175px;
  margin: 0 15px 0 0;
}
</style>

<script>
import VueApexCharts from "vue-apexcharts";
import ServerAPI from "@/mixins/ServerAPI.js";

export default {
  mixins: [ServerAPI],
  components: {
    apexchart: VueApexCharts
  },
  name: "ir-sensor-dash",
  data: function() {
    return {
      series: [],
      facilityName: String(""),
      nextRequestInterval: Number(500),
      maxIrSensorCount: Number(0)
    };
  },
  computed: {
    chartOptions: function() {
      return {
        title: {
          text: this.$getWording("LBL_DASH_IR_CHART_TITLE"),
          align: "left"
        },
        noData: {
          text: "Loading infrared sensor data..."
        },
        chart: {
          animations: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val ? "ON" : "OFF";
          },
          style: {
            colors: ["#000000"]
          }
        },
        xaxis: {
          type: "category"
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        tooltip: {
          custom: function({ ctx, dataPointIndex }) {
            var info = ctx.data.threeDSeries[dataPointIndex];

            // eslint-disable-next-line
            return '<div class="arrow_box">' + // eslint-disable-next-line
                   '<span style="float:left">[Timestamp]   ' + info.timestamp + '</span><br>' + // eslint-disable-next-line
                   '<span style="float:left">[Error ID]    ' + info.errorid + '</span><br>' + // eslint-disable-next-line
                   '<span style="float:left">[Sensor Type] ' + info.sensortype + '</span><br>' + // eslint-disable-next-line
                   '</div>'
          },
          marker: {
            show: true
          },
          fixed: {
            enabled: true,
            position: "topRight",
            offsetX: 0,
            offsetY: 0
          }
        },
        plotOptions: {
          heatmap: {
            radius: 2,
            enableShades: true,
            shadeIntensity: 0.8,
            distributed: false,
            useFillColorAsStroke: false,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 0,
                  name: "off",
                  color: "#D3D3D3"
                },
                {
                  from: 1,
                  to: 1,
                  name: "on",
                  color: "#FF0000"
                }
              ]
            }
          }
        }
      };
    }
  },
  created() {
    // Initialize Infrared Sensor Data container contents
    this.getFacilityNameFromLocalCache();

    // Subscribe to changes on selection of facility
    this.$store.subscribe(mutation => {
      if (mutation.type === "saveDashboardFacilityNameSelected") {
        this.getFacilityNameFromLocalCache();
      }
    });
  },

  methods: {
    getFacilityNameFromLocalCache() {
      var savedFacilityNameSelected = this.$store.state.savedDashboardSettings
        .facilityName;
      this.facilityName = savedFacilityNameSelected;
      this.maxIrSensorCount = 0;
    },

    onInfraredChartRenderDone() {
      var me = this;

      // FOR DEBUG ONLY: Get the time when rendering finished
      // this.timerStopRendering();

      // Request for new data after 500ms
      window.setTimeout(function() {
        me.requestInfraredDataFromServer(me.facilityName);
      }, this.nextRequestInterval);
    },
    requestInfraredDataFromServer(facility_name) {
      var api_request = "infrared/sensordata";
      var queries = {
        facility_name: facility_name
      };

      this.getData(api_request, queries).then(result => {
        this.updateInfraredDataChart(result);
      });
    },
    updateInfraredDataChart(result) {
      if (result.success) {
        var data_list_length = result.data_list.length;
        if (data_list_length > this.maxIrSensorCount) {
          this.maxIrSensorCount = data_list_length;
        }

        if (
          data_list_length === this.maxIrSensorCount ||
          data_list_length === 0
        ) {
          var ir_data_series = [];
          var sortedDataList = result.data_list.sort(function(a, b) {
            return a.sensorid
              .toLowerCase()
              .localeCompare(b.sensorid.toLowerCase());
          });

          sortedDataList.forEach(ir_data => {
            var info = {
              timestamp: ir_data.timestamp,
              errorid: ir_data.errorid,
              sensortype: ir_data.sensortype
            };

            ir_data_series.push({
              x: ir_data.sensorid,
              y: ir_data.result,
              z: info
            });
          });

          this.series = [
            {
              name: "IR Sensors",
              data: ir_data_series
            }
          ];
        }

        // Request for new data after 500ms
        this.nextRequestInterval = 500;

        // this.timerStartRendering();
      } else {
        this.maxIrSensorCount = 0;
        this.series = [
          {
            name: "IR Sensors",
            data: []
          }
        ];

        // Request for new data after 5 seconds
        this.nextRequestInterval = 5000;
      }
    },

    timerStartRendering() {
      console.time("ApexCharts Render Infrared Map");
    },
    timerStopRendering() {
      console.timeEnd("ApexCharts Render Infrared Map");
    }
  }
};
</script>
