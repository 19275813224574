<template>
  <div class="col" id="dashboard-main">
    <div class="row">
      <FacilityDash class="ml-3 ml-1" />
    </div>
    <div class="row">
      <IRDash class="ml-3 mr-1 no-scrollbar" />
    </div>
    <div class="row">
      <DopplerDash class="ml-3 ml-1" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.row {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}

.no-scrollbar {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>

<script>
import IRDash from "@/components/Dashboard/InfraredDash.vue";
import DopplerDash from "@/components/Dashboard/DopplerDash.vue";
import FacilityDash from "@/components/Dashboard/FacilityDash.vue";

export default {
  name: "dashboard-main",
  components: {
    IRDash,
    DopplerDash,
    FacilityDash
  }
};
</script>
