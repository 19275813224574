<template>
  <b-card class="mt-3">
    <!-- Header -->
    <template #header>
      <h6 class="mb-0">{{ $getWording("LBL_CONF_PREVIEW_HEADER") }}</h6>
    </template>

    <!-- Last Modified in S3 -->
    <b-row>{{ config_last_modified }}</b-row>
    <br />

    <!-- Preview Contents in YAML form-->
    <b-row class="yaml-space"
      ><pre class="m-0 text-left">{{ yaml_form }}</pre></b-row
    >
  </b-card>
</template>

<style scoped lang="scss">
@import "@/styles/common.scss";

.yaml-space {
  margin-left: 15px;
}
</style>

<script>
const YAML = require("json-to-pretty-yaml");

export default {
  name: "DeviceConfigPreview",
  props: {
    config_json: {
      type: Object,
      required: true,
      default: () => {}
    },
    last_modified: {
      type: String,
      required: true,
      default: ""
    }
  },
  computed: {
    yaml_form: function() {
      return YAML.stringify(this.config_json);
    },
    config_last_modified: function() {
      return "Last Modified: " + this.last_modified;
    }
  }
};
</script>
