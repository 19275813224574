const AppConfig = {
  // Debugging Modes
  DEBUG: true, // Use a more specific switch if possible
  DBG_SHOW_RENDER_TIME: true,

  // Server API Constants
  DASHBOARD_DEFAULT_SF: 500, // Hz
  DASHBOARD_DEFAULT_WIDTH: 5, // Minutes
  DASHBOARD_DEFAULT_UPDATE_INT: 2, // Seconds

  DEVCFG_DEFAULT_SUBNET_MASK: "255.255.255.0",
  DEVCFG_DEFAULT_MULTICAST_PLACEHOLDER: "239.10.0.1",

  // Placeholders are always in string type
  DEVCFG_DEFAULT_DP_PORT: "9090",
  DEVCFG_DEFAULT_DP_INT_SEC: "10",
  DEVCFG_DEFAULT_DP_GETSTATE_SEC: "20",
  DEVCFG_DEFAULT_DP_SETCLOCK_HR: "12",
  DEVCFG_DEFAULT_SENDINFO_PORT: "8080",
  DEVCFG_DEFAULT_PB_PORT: "30000",
  DEVCFG_DEFAULT_PB_ID: "ROOM_01_01",
  DEVCFG_DEFAULT_PBCAL_MODE: "3",
  DEVCFG_DEFAULT_PBCAL_RANGE: "75",
  DEVCFG_DEFAULT_PBCAL_COUNT: "50",
  DEVCFG_DEFAULT_PBCAL_INT: "4",
  DEVCFG_DEFAULT_PBCAL_TIMER: "120",
  DEVCFG_DEFAULT_IR_TCP_PORT: "20000",
  DEVCFG_DEFAULT_UPLOAD_INT: "300",
  DEVCFG_DEFAULT_UPDATE_INT: "60",

  DEVCFG_DEFAULT_ITEM: "",

  TYPE_NUMBER: "number"
};

AppConfig.install = function(Vue) {
  Vue.prototype.$getConfig = key => {
    return AppConfig[key];
  };
};

export default AppConfig;
