<template>
  <div class="container">
    <b-button class="btn-space" variant="primary" @click="onPing"
      >Ping</b-button
    >
    <div style="text-align:center">
      <br />
      <span>{{ msg }}</span
      ><br />
    </div>
  </div>
</template>

<script>
import ServerAPI from "@/mixins/ServerAPI.js";

export default {
  name: "Ping",
  data() {
    return {
      msg: ""
    };
  },
  mixins: [ServerAPI],
  created() {
    this.requestHealthCheck();
  },
  methods: {
    onPing(evt) {
      evt.preventDefault();
      this.requestHealthCheck();
    },
    requestHealthCheck() {
      this.getHealthCheck().then(result => {
        console.log(result);

        if (result.success) {
          this.msg = result.app;
        }
      });
    }
  }
};
</script>
