import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // Put all app-wide state attributes here.
    //
    // By convention, let's use all-caps to differentiate
    // between state- and component- attributes.

    savedDashboardSettings: {
      facilityName: localStorage.savedDashboardSettings_facilityName,

      // Sampling Rate and Sampling Window are not stored in local cache
      // so that when user refreshes the page, it is set back to default
      dpSensorIDList: [],
      dpSensorID: "",
      dpSamplingRate: "",
      dpSamplingWindow: "",

      dpTimeStart: 0,
      dpTimeDelta: 0
    },
    savedConfigPageFacility: ""
  },
  mutations: {
    // Put all synchronous functions for changing the state.
    saveDashboardFacilityNameSelected: (state, facilityNameSelected) => {
      state.savedDashboardSettings.facilityName = facilityNameSelected;

      // Save in local storage also for retaining when page is refereshed
      localStorage.savedDashboardSettings_facilityName = facilityNameSelected;
    },

    saveDashboardDopplerSensorIDList: (state, sensorIDList) => {
      state.savedDashboardSettings.dpSensorIDList = sensorIDList;
    },
    saveDashboardDopplerSensorIDSelected: (state, sensorIDSelected) => {
      state.savedDashboardSettings.dpSensorID = sensorIDSelected;
    },
    saveDashboardDopplerSamplingHzSelected: (state, samplingHzSelected) => {
      state.savedDashboardSettings.dpSamplingRate = samplingHzSelected;
    },
    saveDashboardDopplerSamplingWinSelected: (state, samplingWinSelected) => {
      state.savedDashboardSettings.dpSamplingWindow = samplingWinSelected;
    },
    saveDashboardDopplerTimeStart: (state, tts_start) => {
      state.savedDashboardSettings.dpTimeStart = tts_start;
    },
    saveDashboardDopplerTimeDelta: (state, tts_delta) => {
      state.savedDashboardSettings.dpTimeDelta = tts_delta;
    },

    saveConfigPageFacilitySelected: (state, facilityNameSelected) => {
      state.savedConfigPageFacility = facilityNameSelected;
    }
  },
  actions: {
    // Put all asynchronous functions that call mutations.
    // Example: Calling APIs
  }
});
