<template>
  <div class="container">
    <div class="group-title">
      {{ $getWording("LBL_CONF_SELECT_TITLE") }}
    </div>
    <b-row>
      <b-form-select
        class="mb-3 select-facility-menu"
        v-model="facilityNameSelected"
        :options="facilityNameChoices"
        required
      ></b-form-select>
      <b-button
        class="mb-3"
        @click="onClickApplyFacilityChange"
        variant="primary"
        >Apply</b-button
      >
    </b-row>
  </div>
</template>

<style scoped lang="scss">
@import "@/styles/common.scss";

.group-title {
  margin: 15px 15px 15px 0;
  font-size: 1.35em;
  font-weight: bold;
  text-align: left;
}

.select-facility-menu {
  width: 500px;
  margin-right: 15px;
  margin-left: 35px;
}
</style>

<script>
import ServerAPI from "@/mixins/ServerAPI.js";

export default {
  name: "facility-selector-dash",
  data: function() {
    return {
      facilityNameChoices: [],
      facilityNameSelected: ""
    };
  },
  mixins: [ServerAPI],
  created() {
    // Get a fresh list of facility names from S3 Config bucket
    // every time this template is created
    this.getFacilityNameChoices();
  },
  methods: {
    onClickApplyFacilityChange(evt) {
      evt.preventDefault();

      var savedFacilityNameSelected = this.$store.state.savedDashboardSettings
        .facilityName;

      if (this.facilityNameSelected !== savedFacilityNameSelected) {
        this.saveFacilityNameSelected(this.facilityNameSelected);
      }
    },
    getFacilityNameChoices() {
      var me = this;
      var refreshID = window.setInterval(
        (function getFacilityNames() {
          me.getData("facility/names").then(result => {
            if (result.success) {
              me.facilityNameChoices = result.facility_name_list.sort(function(
                // eslint-disable-next-line
            a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
              });

              // Right after getting the facility name list,
              // get the selected facility from local cache if existing
              me.getFacilityNameSelected();
              clearInterval(refreshID);
            }
          });
          return getFacilityNames;
        })(),
        5000
      );
    },
    getFacilityNameSelected() {
      var savedFacilityNameSelected = this.$store.state.savedDashboardSettings
        .facilityName;

      if (
        // Set selected facility to default if there's no facility name stored
        this.isNotSet(savedFacilityNameSelected) ||
        // Set selected facility to default if the stored facility
        // does not match any of the names in the current facility name list
        this.isNotInCurrentFacilityList(savedFacilityNameSelected)
      ) {
        this.facilityNameSelected = this.facilityNameChoices[0];
        this.saveFacilityNameSelected(this.facilityNameSelected);
      } else {
        this.facilityNameSelected = savedFacilityNameSelected;
      }
    },
    saveFacilityNameSelected(facility_name) {
      this.$store.commit("saveDashboardFacilityNameSelected", facility_name);
    },

    isNotSet(selection) {
      return selection === undefined;
    },
    isNotInCurrentFacilityList(facility_name) {
      return this.facilityNameChoices.indexOf(facility_name) < 0;
    }
  }
};
</script>
