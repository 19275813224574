<template>
  <div class="setting-ui-container align-items-center">
    <label class="setting-ui-label">
      {{ label }}
    </label>
    <div class="ml-2" />
    <div
      v-for="choice in getChoices"
      :key="choice.id"
      class="form-check-inline"
    >
      <input
        class="form-check-input"
        type="radio"
        :name="name"
        :value="choice.key"
        :checked="choice.key == value"
        :disabled="disabled || choice.disabled"
        @input="didSelect"
      />
      <span>{{ choice.desc }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
label {
  width: 175px;
  margin-right: 20px;
}

.form-check {
  min-width: 50px;
  margin-right: 20px;
}
</style>

<script>
export default {
  name: "SettingsSelect",
  props: {
    name: {
      type: String,
      required: true,
      default: "group-name"
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: String,
      required: true,
      default: "choice"
    },
    choices: {
      type: Array, // { key:String, desc:String }
      required: true,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {};
  },
  computed: {
    getChoices() {
      return this.choices;
    }
  },
  methods: {
    didSelect(evt) {
      this.$emit("input", evt.target.value);
    }
  }
};
</script>
