import Vue from "vue";
import VueRouter from "vue-router";

import Dashboard from "@/views/Dashboard.vue";
import Configs from "@/views/Configs.vue";
import Ping from "@/views/Ping.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard
  },
  {
    path: "/configs",
    name: "device-configs",
    component: Configs
  },
  {
    // NOTE: This is just a page used for verifying connection to backend
    path: "/ping",
    name: "ping",
    component: Ping
  },
  {
    // Catch all redirect
    path: "*",
    redirect: "/dashboard"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
