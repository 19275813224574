<template>
  <b-form-group label-cols-sm="3" label-align-sm="right">
    <!-- Label -->
    <template #label>{{ label }}</template>

    <!-- Input Box -->
    <b-col sm="5"
      ><b-form-input
        :value="value"
        :state="validateHexInt"
        aria-describedby="input-live-feedback"
        :placeholder="placeholder"
        :type="type"
        :disabled="disabled"
        @input="didChange"
      ></b-form-input>

      <!-- This will only be shown if the preceding input has an invalid state -->
      <b-form-invalid-feedback id="input-live-feedback">
        {{ feedback_msg }}
      </b-form-invalid-feedback>
    </b-col>
  </b-form-group>
</template>

<style lang="scss" scoped></style>

<script>
export default {
  name: "FormInputHexOrInt",
  props: {
    label: {
      type: String,
      required: true,
      default: "Change this label"
    },
    value: {
      required: true,
      default: "choice"
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    type: {
      type: String,
      required: false,
      default: "text"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    hexdigitcnt: {
      type: String,
      required: true,
      default: "6"
    }
  },
  data: function() {
    return {
      feedback_msg: "Input a valid integer or hex value",
      valuetype: "empty"
    };
  },
  computed: {
    maxvalue() {
      return Math.pow(2, Number(this.hexdigitcnt) * 4) - 1;
    },
    validateHexInt() {
      if (this.valuetype === "empty") {
        return null;
      } else {
        return this.valuetype === "hexstring" || this.valuetype === "integer";
      }
    }
  },
  methods: {
    didChange(input_text) {
      var emit_value = input_text;
      if (this.isHexValue(input_text)) {
        if (this.isHexValidDigitCnt(input_text)) {
          this.valuetype = "hexstring";
        } else {
          this.valuetype = "invalid";
        }
      } else if (this.isInteger(input_text)) {
        if (this.isIntegerInRange(input_text)) {
          this.valuetype = "integer";
          emit_value = Number(input_text);
        } else {
          this.valuetype = "invalid";
        }
      } else if (input_text === "") {
        this.valuetype = "empty";
      } else {
        this.valuetype = "invalid";
        this.feedback_msg = "Input a valid integer or hex value";
      }
      this.$emit("input", emit_value);
    },
    isInteger(input) {
      var regexp = /^[0-9]+$/;
      return regexp.test(input);
    },
    isIntegerInRange(input) {
      var isIntValid = true;
      var input_number = Number(input);
      if (input_number > this.maxvalue) {
        this.feedback_msg = "Maximum allowed value is " + this.maxvalue;
        isIntValid = false;
      }
      return isIntValid;
    },
    isHexValue(input) {
      var isHex = false;
      var regexp = /^0[xX][0-9a-fA-F]+$/;
      if (regexp.test(input)) {
        isHex = true;
      } else if (input == "0" || input == "0x" || input == "0X") {
        isHex = true;
      }
      return isHex;
    },
    isHexValidDigitCnt(input) {
      var isHexValid = false;
      var hexdigit_exact_count = Number(this.hexdigitcnt) + 2;

      if (input.length < hexdigit_exact_count) {
        this.feedback_msg = "Enter exactly " + this.hexdigitcnt + " hex digits";
      } else if (input.length > hexdigit_exact_count) {
        this.feedback_msg =
          "Maximum allowed value is 0x" + "F".repeat(Number(this.hexdigitcnt));
      } else {
        isHexValid = true;
      }
      return isHexValid;
    }
  }
};
</script>
