// The application's entry point.
//
// This will load and initialize the root Vue component
// using the template defined in the root App.vue file.
// The root component is where all the other components
// will be rendered.
//
// The rendered component is then "mounted" onto the
// div with id="app" defined in index.html.

import Vue from "vue";
import router from "./router";
import store from "./store";

import VueMoment from "vue-moment";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import App from "./App.vue";
import AppConfig from "@/plugins/AppConfig.js";
import AppWordings from "@/plugins/AppWordings.js";

import "@aws-amplify/ui-vue";

Vue.config.debug = false;
Vue.config.silent = false;
Vue.config.strict = true;

Vue.use(AppConfig);
Vue.use(AppWordings, { lang: "en" });
Vue.use(VueMoment);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons, "b-icon");

var timestamp = "[" + Date.now() + "] ";
console.log(timestamp + " NEW Connection...");

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
