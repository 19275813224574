<template>
  <div class="container" ref="dopplermain">
    <div class="group-title d-flex panel-body">
      {{ $getWording("LBL_DASH_DP_PANEL_TITLE") }}
    </div>
    <div class="group d-flex">
      <SettingsSelect
        v-model="samplingHzSelected"
        class="group-row"
        name="select-sampling-rate"
        :label="$getWording('LBL_DASH_DP_CHART_SPS')"
        :choices="samplingHzChoices"
      />
    </div>
    <div class="group d-flex">
      <SettingsSelect
        v-model="samplingWinSelected"
        class="group-row"
        name="select-sampling-length"
        :label="$getWording('LBL_DASH_DP_CHART_LENGTH')"
        :choices="samplingWinChoices"
      />
    </div>
    <div class="group d-flex">
      <SettingsDropdown
        class="group-row"
        :label="$getWording('LBL_DASH_DP_CHART_SENSOR')"
        :choices="sensorIDChoices"
        :selected="sensorIDSelected.desc"
        @on-select="didSelectSensorID"
      />
    </div>
    <div class="group d-flex">
      <b-button
        class="mb-3"
        @click="onClickApplyDopplerSettingsChange"
        variant="primary"
        >Apply</b-button
      >
    </div>
    <div class="chart">
      <apexchart
        ref="dopplerchart"
        width="1100"
        type="line"
        :options="chartOptions"
        :series="series"
        @updated="onDopplerChartRenderDone"
      ></apexchart>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/styles/common.scss";

.group-title {
  margin: 15px 15px 15px 0;
  font-size: 1.35em;
  font-weight: bold;
  text-align: left;
}

.setting-ui-label {
  min-width: 175px;
  margin: 0 15px 0 0;
}
</style>

<script>
import VueApexCharts from "vue-apexcharts";
import SettingsSelect from "@/components/Common/SettingsSelect.vue";
import SettingsDropdown from "@/components/Common/SettingsDropdown.vue";
import ServerAPI from "@/mixins/ServerAPI.js";

export default {
  mixins: [ServerAPI],
  components: {
    apexchart: VueApexCharts,
    SettingsSelect,
    SettingsDropdown
  },
  data() {
    return {
      series: [
        {
          name: "I Data",
          data: Array()
        },
        {
          name: "Q Data",
          data: Array()
        }
      ],
      facilityName: String(""),
      nextRequestInterval: Number(1000),

      samplingHzChoices: Array(),
      samplingHzSelected: String("125"),

      samplingWinChoices: Array(),
      samplingWinSelected: String("1"),

      sensorIDChoices: Array(),
      sensorIDSelected: Object()
    };
  },
  computed: {
    chartOptions: function() {
      return {
        title: {
          text: this.$getWording("LBL_DASH_DP_CHART_TITLE"),
          align: "left"
        },
        chart: {
          id: "realtime",
          type: "line",
          animations: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          title: {
            text: "Timestamp"
          },
          labels: {
            show: true,
            formatter: point_idx => {
              point_idx = Math.trunc(point_idx);
              var tts_start = this.$store.state.savedDashboardSettings
                .dpTimeStart;
              var tts_delta = this.$store.state.savedDashboardSettings
                .dpTimeDelta;

              if (tts_start === undefined || tts_delta === undefined) {
                return 0;
              }

              var tts_adjusted = tts_start + point_idx * tts_delta;
              var tts_iso = new Date(tts_adjusted).toISOString();

              return tts_iso.slice(0, -5);
            }
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0
          },
          tickAmount: 9,
          tickPlacement: "between",
          position: "bottom"
        },
        yaxis: [
          {
            min: -2500,
            max: 2500,
            seriesName: "I Data"
          },
          {
            min: -2500,
            max: 2500,
            seriesName: "Q Data",
            labels: {
              show: false
            }
          }
        ],
        stroke: {
          curve: "smooth",
          width: 0.5
        },
        noData: {
          text: "Loading doppler sensor data..."
        },
        markers: {
          size: 0
        }
      };
    }
  },
  created() {
    // Initialize Doppler Sensor Data container contents

    // TODO: Put options in a separate config
    // eslint-disable-next-line
    this.samplingHzChoices = [      // eslint-disable-next-line
      { key: 10,  desc: "10 Hz" },  // eslint-disable-next-line
      { key: 25,  desc: "25 Hz" },  // eslint-disable-next-line
      { key: 125, desc: "125 Hz"},  // eslint-disable-next-line
      { key: 250, desc: "250 Hz"},  // eslint-disable-next-line
      { key: 500, desc: "500 Hz"},  // eslint-disable-next-line
      { key: 1000, desc: "1000 Hz"}
    ];

    // eslint-disable-next-line
    this.samplingWinChoices = [   // eslint-disable-next-line
      { key: 1, desc: "1-min" },  // eslint-disable-next-line
      { key: 2, desc: "2-min" },  // eslint-disable-next-line
      { key: 3, desc: "3-min" },  // eslint-disable-next-line
      { key: 4, desc: "4-min" },  // eslint-disable-next-line
      { key: 5, desc: "5-min"}    // eslint-disable-next-line
    ];

    this.getFacilityNameFromLocalCache();

    var savedSettings = this.$store.state.savedDashboardSettings;
    if (
      savedSettings.dpSensorIDList === undefined ||
      savedSettings.dpSensorIDList.length === 0
    ) {
      this.getSensorIDListFromServer(this.facilityName);
    } else {
      this.getDopplerSettingsFromLocalCache();
    }

    // Subscribe to changes on selection of facility
    this.$store.subscribe(mutation => {
      if (mutation.type === "saveDashboardFacilityNameSelected") {
        this.getFacilityNameFromLocalCache();
        this.getSensorIDListFromServer(this.facilityName);
      }
    });
  },
  methods: {
    // UI Action Handlers
    didSelectSensorID(selected) {
      this.sensorIDSelected = this.sensorIDChoices.find(
        ({ key }) => key == selected
      );
    },
    onClickApplyDopplerSettingsChange() {
      this.saveDopplerSettingsToLocalCache(
        [],
        this.sensorIDSelected.desc,
        this.samplingHzSelected,
        this.samplingWinSelected
      );
    },

    // Getting items from server
    getSensorIDListFromServer(facility_name) {
      if (facility_name) {
        var api_request = "facility/" + facility_name + "/dp_sensorids";
        this.getData(api_request).then(result => {
          if (result.success) {
            // eslint-disable-next-line
            var sortedSensorIdList = result.dp_sensor_id_list.sort(function(a, b ) {
              return a.toLowerCase().localeCompare(b.toLowerCase());
            });

            this.saveDopplerSettingsToLocalCache(
              sortedSensorIdList,
              sortedSensorIdList[0],
              this.samplingHzSelected,
              this.samplingWinSelected
            );
          } else {
            this.saveDopplerSettingsToLocalCache(
              [],
              "",
              this.samplingHzSelected,
              this.samplingWinSelected
            );
          }
          this.getDopplerSettingsFromLocalCache();
        });
      }
    },

    // Getting items from local cache
    getFacilityNameFromLocalCache() {
      var savedFacilityNameSelected = this.$store.state.savedDashboardSettings
        .facilityName;
      this.facilityName = savedFacilityNameSelected;
    },
    getDopplerSettingsFromLocalCache() {
      var savedSettings = this.$store.state.savedDashboardSettings;

      if (savedSettings.dpSensorIDList.length === 0) {
        this.sensorIDChoices = [];
        this.sensorIDSelected = "";
      } else {
        this.sensorIDChoices = this.generateSensorIDChoicesFromStrList(
          savedSettings.dpSensorIDList
        );
        this.sensorIDSelected = this.sensorIDChoices.find(
          ({ desc }) => desc == savedSettings.dpSensorID
        );
      }

      this.samplingHzSelected = savedSettings.dpSamplingRate;
      this.samplingWinSelected = savedSettings.dpSamplingWindow;
    },

    // Saving to local cache
    saveDopplerSettingsToLocalCache(
      sensorIdList,
      sensorIdStr,
      samplingHz,
      samplingWin
    ) {
      this.$store.commit("saveDashboardDopplerSensorIDList", sensorIdList);
      this.$store.commit("saveDashboardDopplerSensorIDSelected", sensorIdStr);
      this.$store.commit("saveDashboardDopplerSamplingHzSelected", samplingHz);
      this.$store.commit(
        "saveDashboardDopplerSamplingWinSelected",
        samplingWin
      );
    },

    generateSensorIDChoicesFromStrList(sensorIdList) {
      var sensorIDChoices = [];

      if (sensorIdList) {
        var sortedSensorIdList = sensorIdList.sort(function(a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });

        for (var idx in sortedSensorIdList) {
          sensorIDChoices.push({
            key: idx,
            desc: sortedSensorIdList[idx]
          });
        }
      }
      return sensorIDChoices;
    },

    // Methods for handling Sensor Data
    onDopplerChartRenderDone() {
      var me = this;

      // FOR DEBUG ONLY: Get the time when rendering finished
      // this.timerStopRendering();

      // Request for new data after 1 second
      window.setTimeout(function() {
        me.requestDopplerDataFromServer();
      }, this.nextRequestInterval);
    },
    requestDopplerDataFromServer() {
      var api_request = "doppler/sensordata";
      var queries = this.generateQueryFromSavedSensorSettings();

      this.getData(api_request, queries).then(result => {
        this.updateDopplerDataChart(result);
      });
    },
    generateQueryFromSavedSensorSettings() {
      var savedSettings = this.$store.state.savedDashboardSettings;
      var queries = {
        facility_name: savedSettings.facilityName,
        dp_sensor_id: savedSettings.dpSensorID,
        dp_sampling_rate: Number(savedSettings.dpSamplingRate),
        dp_sampling_window: Number(savedSettings.dpSamplingWindow)
      };
      return queries;
    },
    updateDopplerDataChart(result) {
      this.$store.commit("saveDashboardDopplerTimeStart", result.tts_start);
      this.$store.commit("saveDashboardDopplerTimeDelta", result.tts_delta);

      if (result.success) {
        this.series = [
          {
            name: "I Data",
            data: result.i_data_list
          },
          {
            name: "Q Data",
            data: result.q_data_list
          }
        ];

        // Request for new data after 1 second
        this.nextRequestInterval = 1000;
      } else {
        this.series = [
          {
            name: "I Data",
            data: []
          },
          {
            name: "Q Data",
            data: []
          }
        ];

        this.getSensorIDListFromServer(this.facilityName);

        // Request for new data after 5 seconds
        this.nextRequestInterval = 5000;
      }

      // TODO: Clear cache if data points exceed expected number of points
      // var expected_data_point_count = this.samplingHzSelected * this.samplingWinSelected * 60;
      // var updated_data_series_len = this.series[0].data.length;
      // if((result.data_points_count < expected_data_point_count) ||
      //    (updated_data_series_len > expected_data_point_count)){
      //   console.debug("Redrawing chart")
      //   // Redraw the whole window if the data are still incomplete for desired window
      //   this.$refs.dopplerchart.updateOptions (this.chartOptions, true, false, true);
      // }
      // else{
      //   console.debug("Normal rendering")
      // }
      // this.timerStartRendering();
    },

    timerStartRendering() {
      console.time(
        // eslint-disable-next-line
        "ApexCharts Render Doppler " + this.samplingHzSelected + "Hz " + // eslint-disable-next-line
          this.samplingWinSelected + "-min"
      );
    },
    timerStopRendering() {
      console.timeEnd(
        // eslint-disable-next-line
        "ApexCharts Render Doppler " + this.samplingHzSelected + "Hz " + // eslint-disable-next-line
          this.samplingWinSelected + "-min"
      );
    }
  }
};
</script>
