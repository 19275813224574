<template>
  <div class="setting-ui-container align-items-center my-2">
    <label class="setting-ui-label">
      {{ label }}
    </label>
    <b-dropdown
      split
      :split-variant="txtVariant"
      :variant="btnVariant"
      :text="displayedText"
      :disabled="disabled"
      size="m"
    >
      <b-dropdown-item
        v-for="choice in choices"
        :key="choice.id"
        @click="onSelect(choice)"
      >
        {{ choice.desc }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/common.scss";
</style>

<script>
export default {
  name: "SettingsDropdown",
  props: {
    label: {
      type: String,
      required: false,
      default: ""
    },
    choices: {
      type: Array, // { key:String, desc:String }
      required: true,
      default: () => []
    },
    selected: {
      type: String,
      required: true,
      default: ""
    },
    applied: {
      type: Boolean,
      required: false,
      default: false
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      currentlySelected: this.selected,
      appliedSelection: null
    };
  },
  computed: {
    displayedText() {
      return this.invalid
        ? this.$getWording("LBL_BLANK")
        : this.currentlySelected;
    },
    txtVariant() {
      let splitVariant = "outline-primary";

      if (this.applied || this.currentlySelected === this.appliedSelection) {
        splitVariant = "primary";
      } else if (this.invalid) {
        splitVariant = "secondary";
      }

      return splitVariant;
    },
    btnVariant() {
      return this.invalid ? "secondary" : "primary";
    }
  },
  watch: {
    selected(newVal) {
      this.currentlySelected = newVal;
    },
    applied(newVal) {
      if (newVal) {
        this.updateAppliedSelection(this.currentlySelected);
      }
    }
  },
  methods: {
    onSelect(choice) {
      this.currentlySelected = choice.desc;
      this.$emit("on-select", choice.key);
    },
    updateAppliedSelection(choice) {
      this.appliedSelection = choice;
    }
  }
};
</script>
