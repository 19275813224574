<template>
  <div id="app">
    <!-- Top-most Navigation Bar -->
    <b-navbar class="custom-nav-bar">
      <!-- App Title Bar -->
      <b-navbar-brand class="font-weight-bold">
        <span>{{ $getWording("APP_NAME") }}</span>
        &nbsp;
        <span>{{ $getWording("APP_VERSION") }}</span>
      </b-navbar-brand>

      <!-- Navigation tab to dashboard or config -->
      <div
        class="collapse navbar-collapse"
        id="navbarNav"
        v-if="authState === 'signedin' && user"
      >
        <ul class="navbar-nav">
          <li class="nav-item active">
            <router-link to="/dashboard">
              <a class="nav-link">
                <b-icon class="nav-icon" icon="display"></b-icon>
                {{ $getWording("TAB_SENSOR_DASHBOARD")
                }}<span class="sr-only">(current)</span>
              </a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/configs">
              <a class="nav-link">
                <b-icon class="nav-icon" icon="gear"></b-icon>
                {{ $getWording("TAB_CONFIG_SETTINGS") }}
              </a>
            </router-link>
          </li>
        </ul>
      </div>

      <!-- User name and Sign out button -->
      <div v-if="authState === 'signedin' && user">
        <b-row align-v="center">
          <b-col>Hello, {{ user.username }}</b-col>
          <b-col><amplify-sign-out></amplify-sign-out></b-col>
        </b-row>
      </div>
    </b-navbar>

    <!-- Amplify Authenticator Component -->
    <div v-if="authState !== 'signedin'">
      <amplify-authenticator class="authenticator-style">
        <amplify-sign-in slot="sign-in" :hide-sign-up="true"> </amplify-sign-in>
      </amplify-authenticator>
    </div>

    <!-- Routing to main Views -->
    <div v-if="authState === 'signedin' && user">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss">
.router-view {
  height: 100%;
}

.custom-nav-bar {
  background-color: whitesmoke;
}

.nav-icon {
  margin: 0 0 0 15px;
}

amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
  background-color: whitesmoke;
}

:root {
  --amplify-primary-color: #007bff;
  --amplify-primary-tint: #007bfd;
  --amplify-primary-shade: #007bfd;
}

#app {
  height: 100%;
  color: #2c3e50;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  text-align: center;
  // background: lightgray;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#title {
  padding: 25px;
  font-size: 2em;
  font-weight: bold;
}
</style>

<script>
// Reference: https://docs.amplify.aws/ui/auth/authenticator/q/framework/vue
import { onAuthUIStateChange } from "@aws-amplify/ui-components";

export default {
  name: "AuthStateApp",
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    });
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined
    };
  },
  beforeDestroy() {
    this.unsubscribeAuth();
  }
};
</script>
