<template>
  <b-form-group label-cols-sm="3" label-align-sm="right">
    <!-- Label -->
    <template #label>{{ label }}</template>

    <!-- Input Box -->
    <b-col sm="5"
      ><b-form-input
        :value="value"
        :placeholder="placeholder"
        :type="type"
        :disabled="disabled"
        :min="0"
        @input="didChange"
      ></b-form-input
    ></b-col>
  </b-form-group>
</template>

<style lang="scss" scoped></style>

<script>
export default {
  name: "FormInput",
  props: {
    label: {
      type: String,
      required: true,
      default: "Change this label"
    },
    value: {
      required: true,
      default: "choice"
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    type: {
      type: String,
      required: false,
      default: "text"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {};
  },
  methods: {
    didChange(input_text) {
      if (this.type === "number") {
        this.$emit("input", Number(input_text));
      } else {
        this.$emit("input", input_text);
      }
    }
  }
};
</script>
