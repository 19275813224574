<template>
  <b-form-group label-cols-sm="3" label-align-sm="right">
    <!-- Label -->
    <template #label>{{ label }}</template>

    <!-- Check Box -->
    <b-col sm="1"
      ><b-form-checkbox :checked="value" @change="didChange"
    /></b-col>
  </b-form-group>
</template>

<style lang="scss" scoped></style>

<script>
export default {
  name: "FormCheckbox",
  props: {
    label: {
      type: String,
      required: true,
      default: "Change this label"
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data: function() {
    return {};
  },
  methods: {
    didChange(event) {
      this.$emit("input", event);
    }
  }
};
</script>
