import { render, staticRenderFns } from "./DopplerDash.vue?vue&type=template&id=0b2aa512&scoped=true&"
import script from "./DopplerDash.vue?vue&type=script&lang=js&"
export * from "./DopplerDash.vue?vue&type=script&lang=js&"
import style0 from "./DopplerDash.vue?vue&type=style&index=0&id=0b2aa512&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b2aa512",
  null
  
)

export default component.exports