<template>
  <b-form-group label-cols-sm="3" label-align-sm="right">
    <!-- Label -->
    <template #label>{{ label }}</template>

    <!-- Input Box -->
    <b-col sm="5"
      ><b-form-input
        :value="value"
        :state="validateIPaddress"
        aria-describedby="input-live-feedback"
        :placeholder="placeholder"
        :type="type"
        :disabled="disabled"
        @input="didChange"
      ></b-form-input>

      <!-- This will only be shown if the preceding input has an invalid state -->
      <b-form-invalid-feedback id="input-live-feedback">
        Enter a valid IPv4 address
      </b-form-invalid-feedback>
    </b-col>
  </b-form-group>
</template>

<style lang="scss" scoped></style>

<script>
export default {
  name: "FormInputIPAddress",
  props: {
    label: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      required: true,
      default: "choice"
    },
    placeholder: {
      type: String,
      required: false,
      default: "192.168.XXX.XXX"
    },
    type: {
      type: String,
      required: false,
      default: "text"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {};
  },
  computed: {
    validateIPaddress() {
      var ipregexp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      if (this.value === "") {
        return null;
      } else {
        return ipregexp.test(this.value);
      }
    }
  },
  methods: {
    didChange(input_text) {
      this.$emit("input", input_text);
    }
  }
};
</script>
