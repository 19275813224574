import Amplify, { API, Auth } from "aws-amplify";

const PROTOCOL = window.location.protocol;
const HOSTNAME = process.env.VUE_APP_BACKEND_API_HOSTNAME;
const HOSTPORT = window.location.port;
// Use the environment host port in env file for local dev
// const HOSTPORT = process.env.VUE_APP_BACKEND_API_HOSTPORT;

const BASE_APP_URL = PROTOCOL + "//" + HOSTNAME + ":" + HOSTPORT;
const BASE_API_URL = BASE_APP_URL + "/v1";
const BASE_API_NAME = process.env.VUE_APP_API_GATEWAY_NAME;

Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_AMPLIFY_AUTH_REGION,
    userPoolId: process.env.VUE_APP_AMPLIFY_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_AMPLIFY_AUTH_WEB_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: BASE_API_NAME,
        endpoint: BASE_API_URL,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`
          };
        }
      }
    ]
  }
});

export default {
  methods: {
    // Make error-handling consistent by returning the server's
    // error state and description.
    handleError(error) {
      let errorInfo = {
        success: false,
        desc: error || ""
      };
      return errorInfo;
    },
    //---------------------------------------------------------------//
    // Public Methods
    //---------------------------------------------------------------//
    // ---------------------------- GET ---------------------------- //
    // async getFacilityNames() {
    //   return this.getData("healthcheck");
    // },

    //---------------------------------------------------------------//
    // Private Methods
    //---------------------------------------------------------------//
    async getHealthCheck() {
      const path = "";

      try {
        const response = await API.get(BASE_API_NAME, path);
        return {
          success: true,
          ...response
        };
      } catch (error) {
        // status code != 200
        console.error(error);
        return this.handleError(error);
      }
    },
    async getData(apiName, queries = {}) {
      const path = "/" + apiName;

      try {
        if (queries === undefined) {
          const response = await API.get(BASE_API_NAME, path);
          return {
            success: true,
            ...response
          };
        } else {
          const response = await API.get(BASE_API_NAME, path, {
            queryStringParameters: queries
          });
          return {
            success: true,
            ...response
          };
        }
      } catch (error) {
        // status code != 200
        return this.handleError(error);
      }
    },
    async postData(apiName, payload = {}) {
      const path = "/" + apiName;

      try {
        const response = await API.post(BASE_API_NAME, path, { body: payload });
        return {
          success: true,
          ...response
        };
      } catch (error) {
        // status code != 200
        return this.handleError(error);
      }
    }
  }
};
