<template>
  <b-row id="configs">
    <!-- Navigation Bar on the Left -->
    <b-col sm="1" class="custom-navbar-color">
      <NavigationBar />
    </b-col>

    <!-- Device Config Proper -->
    <b-col sm="5">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <!-- Select Facility -->
        <b-form-group
          id="selectfacility"
          label-rows-lg="3"
          label="Select Facility"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          label-align="left"
          class="mb-0 border-space"
          style="border:thin solid #D3D3D3"
        >
          <FormSelect
            v-model="facility_selected"
            :label="$getWording('LBL_CONF_FACILITY')"
            :choices="facility_names"
          />
        </b-form-group>

        <!-- Select Firmware Version -->
        <b-form-group
          id="selectfwversion"
          :label="$getWording('LBL_CONF_VERSION_TITLE')"
          label-rows-lg="3"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          label-align="left"
          class="mb-0 border-space"
          style="border:thin solid #D3D3D3"
        >
          <FormSelect
            v-model="form.firmware.version"
            :label="$getWording('LBL_CONF_VERSION')"
            :choices="firmware_versions"
          />
        </b-form-group>

        <!-- IP Address List -->
        <b-form-group
          id="ipaddrlist"
          label-rows-lg="3"
          label="IP Address List"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          label-align="left"
          class="mb-0 border-space"
          style="border:thin solid #D3D3D3"
        >
          <FormInputIPAddress
            v-model="form.ipaddrlist.subnetmask"
            :label="$getWording('LBL_CONF_SUBNET_MASK')"
            :placeholder="$getConfig('DEVCFG_DEFAULT_SUBNET_MASK')"
          />
          <FormInputIPAddress
            v-model="form.ipaddrlist.openblocks"
            :label="$getWording('LBL_CONF_OB_IP_ADDR')"
          />
          <FormInputIPAddress
            v-model="form.ipaddrlist.powerbox"
            :label="$getWording('LBL_CONF_PWRBX_IP_ADDR')"
          />
          <FormInputIPAddress
            v-model="form.ipaddrlist.ir_dest_ip"
            :label="$getWording('LBL_CONF_IR_DEST')"
            :placeholder="$getConfig('DEVCFG_DEFAULT_MULTICAST_PLACEHOLDER')"
          />
          <!-- IR Sensors IP Addresses Table -->
          <b-form-group
            id="input-irsensors-group"
            label-cols-sm="3"
            label="IR Sensor IP Address"
            label-for="input-irsensors"
            label-align-sm="right"
          >
            <b-col sm="6">
              <b-row>
                <b-col>
                  <b-form-input
                    id="input-irsensors"
                    v-model="ir_ip_addr_new"
                    placeholder="192.168.XXX.XXX"
                  ></b-form-input
                ></b-col>
                <b-button @click="onAddIrIpAddress" variant="primary"
                  >Add</b-button
                >
              </b-row>
              <b-table
                striped
                hover
                :items="ir_ip_addrlist"
                :fields="ir_ip_addrfields"
              >
                <template #cell(IP_address)="row">
                  <b-row>
                    <b-col>{{ row.item.IP_address }}</b-col>
                    <b-button
                      rounded
                      size="sm"
                      variant="danger"
                      @click="onRemoveIrIpAddress(row.index)"
                    >
                      <b-icon icon="dash-circle"></b-icon>
                    </b-button>
                  </b-row>
                </template>
              </b-table>
            </b-col>
          </b-form-group>
        </b-form-group>

        <!-- Doppler Sensors -->
        <b-form-group
          id="doppler"
          label-rows-lg="3"
          label="Doppler Sensors"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          label-align="left"
          class="mb-0 border-space"
          style="border:thin solid #D3D3D3"
        >
          <FormCheckbox
            id="checkbox-doppler-enable"
            v-model="form.doppler.enable"
            :label="$getWording('LBL_COMMON_ENABLE')"
          />
          <FormSelect
            id="select-doppler-mode"
            v-model="form.doppler.mode"
            :label="$getWording('LBL_COMMON_MODE_SELECT')"
            :choices="mode_selection"
          />
          <FormInput
            v-model="form.doppler.data_acquisition.port"
            :label="$getWording('LBL_CONF_DP_DAQ_PORT')"
            :placeholder="$getConfig('DEVCFG_DEFAULT_DP_PORT')"
            :type="$getConfig('TYPE_NUMBER')"
          />
          <FormInput
            v-model="form.doppler.data_acquisition.interval"
            :label="$getWording('LBL_CONF_DP_DAQ_INTERVAL')"
            :placeholder="$getConfig('DEVCFG_DEFAULT_DP_INT_SEC')"
            :type="$getConfig('TYPE_NUMBER')"
          />
          <FormInput
            v-model="form.doppler.getstate_task.interval_sec"
            :label="$getWording('LBL_CONF_DP_GETSTATE_INTERVAL')"
            :placeholder="$getConfig('DEVCFG_DEFAULT_DP_GETSTATE_SEC')"
            :type="$getConfig('TYPE_NUMBER')"
          />
          <FormCheckbox
            id="checkbox-doppler-sendinfo-enable"
            v-model="form.doppler.sendinfo_task.enable"
            :label="$getWording('LBL_CONF_DP_SENDINFO_ENABLE')"
          />
          <FormInput
            v-model="form.doppler.sendinfo_task.port"
            :label="$getWording('LBL_CONF_DP_SENDINFO_PORT')"
            :placeholder="$getConfig('DEVCFG_DEFAULT_SENDINFO_PORT')"
            :type="$getConfig('TYPE_NUMBER')"
          />
          <FormCheckbox
            id="checkbox-doppler-setclock-enable"
            v-model="form.doppler.setclock_task.enable"
            :label="$getWording('LBL_CONF_DP_SETCLOCK_ENABLE')"
          />
          <FormInput
            v-model="form.doppler.setclock_task.interval_hr"
            :label="$getWording('LBL_CONF_DP_SETCLOCK_INTERVAL')"
            :placeholder="$getConfig('DEVCFG_DEFAULT_DP_SETCLOCK_HR')"
            :type="$getConfig('TYPE_NUMBER')"
          />
          <FormInput
            v-model="form.doppler.powerbox.port"
            :label="$getWording('LBL_CONF_DP_PWRBX_PORT')"
            :placeholder="$getConfig('DEVCFG_DEFAULT_PB_PORT')"
            :type="$getConfig('TYPE_NUMBER')"
          />
          <FormInput
            v-model="form.doppler.powerbox.id"
            :label="$getWording('LBL_CONF_DP_PWRBX_ID')"
            :placeholder="$getConfig('DEVCFG_DEFAULT_PB_ID')"
          />

          <!-- Power Box Calibration Group -->
          <b-form-group
            label="Power Box Calibration"
            label-size="md"
            label-class="font-weight-bold pt-0"
            label-align="left"
            class="subtitle-space"
          >
            <FormInput
              v-model="form.doppler.powerbox.calibration.mode"
              :label="$getWording('LBL_CONF_PBCAL_MODE')"
              :placeholder="$getConfig('DEVCFG_DEFAULT_PBCAL_MODE')"
              :type="$getConfig('TYPE_NUMBER')"
            />
            <FormInput
              v-model="form.doppler.powerbox.calibration.range"
              :label="$getWording('LBL_CONF_PBCAL_RANGE')"
              :placeholder="$getConfig('DEVCFG_DEFAULT_PBCAL_RANGE')"
              :type="$getConfig('TYPE_NUMBER')"
            />
            <FormInput
              v-model="form.doppler.powerbox.calibration.count"
              :label="$getWording('LBL_CONF_PBCAL_COUNT')"
              :placeholder="$getConfig('DEVCFG_DEFAULT_PBCAL_COUNT')"
              :type="$getConfig('TYPE_NUMBER')"
            />
            <FormInput
              v-model="form.doppler.powerbox.calibration.int"
              :label="$getWording('LBL_CONF_PBCAL_INT')"
              :placeholder="$getConfig('DEVCFG_DEFAULT_PBCAL_INT')"
              :type="$getConfig('TYPE_NUMBER')"
            />
            <FormInput
              v-model="form.doppler.powerbox.calibration.timer"
              :label="$getWording('LBL_CONF_PBCAL_TIMER')"
              :placeholder="$getConfig('DEVCFG_DEFAULT_PBCAL_TIMER')"
              :type="$getConfig('TYPE_NUMBER')"
            />
          </b-form-group>

          <!-- Doppler Sensors List -->
          <b-form-group
            label="Doppler Sensors List"
            label-size="md"
            label-class="font-weight-bold pt-0"
            label-align="left"
            class="subtitle-space"
          >
            <!-- Doppler Sensors Settings Input -->
            <b-form-group>
              <FormInput
                v-model="dp_sensor_new_port"
                label="Port"
                placeholder="1"
                :type="$getConfig('TYPE_NUMBER')"
              />
              <FormInput
                v-model="dp_sensor_new.sensorid"
                label="Sensor ID"
                placeholder="R01-DS01"
              />
              <FormInputHexOrInt
                v-model="dp_sensor_new.gain"
                label="Gain"
                placeholder="0x002041"
                hexdigitcnt="6"
              />
              <FormInputHexOrInt
                v-model="dp_sensor_new.channel"
                label="Channel"
                placeholder="0x0ED000"
                hexdigitcnt="6"
              />
              <FormInput
                v-model="dp_sensor_new.samplingrate"
                label="Sampling Rate"
                placeholder="1000"
                :type="$getConfig('TYPE_NUMBER')"
              />
              <b-button @click="onAddDopplerSensor" variant="primary"
                >Add</b-button
              >
            </b-form-group>

            <!-- Doppler Sensors Table -->
            <b-form-group label-cols-sm="1" label-align-sm="right">
              <b-table
                striped
                hover
                :items="dp_sensor_list"
                :fields="dp_sensor_fields"
              >
                <template #cell(samplingrate)="row">
                  <b-row>
                    <b-col>{{ row.item.samplingrate }}</b-col>
                    <b-button
                      rounded
                      size="sm"
                      variant="danger"
                      @click="onRemoveDopplerSensor(row, row.index)"
                    >
                      <b-icon icon="dash-circle"></b-icon>
                    </b-button>
                  </b-row>
                </template>
              </b-table>
            </b-form-group>
            <!-- Doppler Sensors Table -->
          </b-form-group>
          <!-- Doppler Sensors List -->
        </b-form-group>
        <!-- Doppler Sensors -->

        <!-- Infrared Sensors -->
        <b-form-group
          id="infrared"
          label-rows-lg="3"
          label="Infrared Sensors"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          label-align="left"
          class="mb-0 border-space"
          style="border:thin solid #D3D3D3"
        >
          <FormCheckbox
            id="checkbox-infrared-enable"
            v-model="form.infrared.enable"
            :label="$getWording('LBL_COMMON_ENABLE')"
          />
          <FormSelect
            id="select-infrared-mode"
            v-model="form.infrared.mode"
            :label="$getWording('LBL_COMMON_MODE_SELECT')"
            :choices="mode_selection"
          />
          <FormInput
            v-model="form.infrared.sensor.network.tcpPort"
            :label="$getWording('LBL_CONF_IR_TCP_PORT')"
            :placeholder="$getConfig('DEVCFG_DEFAULT_IR_TCP_PORT')"
            :type="$getConfig('TYPE_NUMBER')"
          />
          <b-form-group
            label="IR Sensors List"
            label-size="md"
            label-class="font-weight-bold pt-0"
            label-align="left"
            class="subtitle-space"
          >
            <b-form-group>
              <FormInput
                v-model="ir_sensor_new.ip4thByte"
                label="DIP Switch (IPv4 Last Byte)"
                placeholder="20"
                :type="$getConfig('TYPE_NUMBER')"
              />
              <FormInputHexOrInt
                v-model="ir_sensor_new.threshold"
                label="Threshold"
                placeholder="0x0495"
                hexdigitcnt="4"
              />
              <FormInput
                v-model="ir_sensor_new.acqInterval"
                label="Acquisition Interval (ms)"
                placeholder="1000"
                :type="$getConfig('TYPE_NUMBER')"
              />

              <b-button @click="onAddInfraredSensor" variant="primary"
                >Add</b-button
              >
            </b-form-group>

            <b-form-group label-cols-sm="1" label-align-sm="right">
              <b-col>
                <b-table
                  striped
                  hover
                  :items="form.infrared.sensor.list"
                  :fields="ir_sensor_fields"
                >
                  <template #cell(acqInterval)="row">
                    <b-row>
                      <b-col>{{ row.item.acqInterval }}</b-col>

                      <b-button
                        rounded
                        size="sm"
                        variant="danger"
                        @click="onRemoveInfraredSensor(row.index)"
                      >
                        <b-icon icon="dash-circle"></b-icon>
                      </b-button>
                    </b-row>
                  </template>
                </b-table>
              </b-col>
            </b-form-group>
          </b-form-group>
        </b-form-group>

        <!-- Sensor Data Uploader -->
        <b-form-group
          id="uploader"
          :label="$getWording('LBL_CONF_UPLOADER_TITLE')"
          label-rows-lg="3"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          label-align="left"
          class="mb-0 border-space"
          style="border:thin solid #D3D3D3"
        >
          <FormCheckbox
            id="checkbox-uploader-enable"
            v-model="sensdata_uploader_enable"
            :label="$getWording('LBL_COMMON_ENABLE')"
          />
          <FormSelect
            v-model="uploader_choice"
            :label="$getWording('LBL_CONF_UPLOAD_PATH')"
            :choices="uploader_selection"
            :disabled="!sensdata_uploader_enable || !version_has_rtviz_support"
          />
          <FormInput
            v-model="form.uploader.upload_interval"
            :label="$getWording('LBL_CONF_UPLOAD_INT')"
            :placeholder="$getConfig('DEVCFG_DEFAULT_UPLOAD_INT')"
            :type="$getConfig('TYPE_NUMBER')"
            :disabled="
              form.uploader_kinesis.enable || !sensdata_uploader_enable
            "
          />
        </b-form-group>

        <!-- Device Config Updater Settings -->
        <b-form-group
          id="confupdater"
          :label="$getWording('LBL_CONF_UPDATER_TITLE')"
          label-rows-lg="3"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          label-align="left"
          class="mb-0 border-space"
          style="border:thin solid #D3D3D3"
        >
          <FormInput
            v-model="form.configupdater.update_interval"
            :label="$getWording('LBL_CONF_UPDATE_INT')"
            :placeholder="$getConfig('DEVCFG_DEFAULT_UPDATE_INT')"
            :type="$getConfig('TYPE_NUMBER')"
          />
        </b-form-group>

        <b-button class="btn-space" type="submit" variant="primary"
          >Save</b-button
        >
        <b-button class="btn-space" type="reset" variant="danger"
          >Reset</b-button
        >
      </b-form>
    </b-col>

    <!-- Device Configuration File Preview -->
    <b-col sm="5">
      <Preview :config_json="form" :last_modified="last_modified" />
    </b-col>
  </b-row>
</template>

<style scoped lang="scss">
@import "@/styles/common.scss";

.btn-space {
  margin-top: 15px;
  margin-right: 15px;
  margin-bottom: 1150px;
  margin-left: 15px;
}

.border-space {
  margin-top: 15px;
  margin-right: 5px;
  margin-bottom: 15px;
  margin-left: 5px;
}

.subtitle-space {
  margin-left: 15px;
}

.custom-navbar-color {
  background-color: #f5f5f5;
}
</style>

<script>
import ServerAPI from "@/mixins/ServerAPI.js";
import NavigationBar from "@/components/DeviceConfigs/NavigationBar.vue";
import Preview from "@/components/DeviceConfigs/Preview.vue";
import FormSelect from "@/components/Common/FormSelect.vue";
import FormInput from "@/components/Common/FormInput.vue";
import FormInputHexOrInt from "@/components/Common/FormInputHexOrInt.vue";
import FormInputIPAddress from "@/components/Common/FormInputIPAddress.vue";
import FormCheckbox from "@/components/Common/FormCheckbox.vue";

export default {
  mixins: [ServerAPI],
  components: {
    NavigationBar,
    Preview,
    FormSelect,
    FormInput,
    FormInputHexOrInt,
    FormInputIPAddress,
    FormCheckbox
  },
  data() {
    return {
      facility_names: [],
      facility_selected: "",

      // Main Device Config
      form: {
        firmware: {
          version: "",
          overwrite_from_usb: true
        },
        ipaddrlist: {
          subnetmask: "",
          openblocks: "",
          powerbox: "",
          ir_dest_ip: "",
          irsensors: []
        },
        doppler: {
          enable: false,
          mode: "",
          data_acquisition: {
            port: null,
            interval: null,
            start_port: ""
          },
          sendinfo_task: {
            enable: false,
            port: null,
            type: "",
            logfile: ""
          },
          getstate_task: {
            interval_sec: null,
            logfile: ""
          },
          setclock_task: {
            enable: false,
            interval_hr: null
          },
          powerbox: {
            port: null,
            id: "",
            calibration: {
              mode: null,
              range: null,
              count: null,
              int: null,
              timer: null
            }
          },
          doppler_sensors: {}
        },
        infrared: {
          enable: false,
          mode: "",
          getstate_task: {
            interval_sec: null
          },
          sensor: {
            network: {
              tcpPort: null,
              tcpTimeout: null,
              tcpRetry: null,
              tcpRetryInterval: null,
              udpPort: null,
              udpTimeout: null,
              udpRetry: null
            },
            list: []
          }
        },
        uploader: {
          enable: false,
          facility: "",
          max_upload_record: null,
          upload_until_empty: false,
          mock_upload: false,
          upload_interval: null
        },
        uploader_kinesis: {
          enable: false,
          facility: ""
        },
        configupdater: {
          facility: "",
          update_interval: null
        },
        loguploader: {
          facility: "",
          update_interval: null
        }
      },

      firmware_versions: [],
      mode_selection: ["sub", "main"],

      // Doppler Sensor Details
      dp_sensor_new_port: null,
      dp_sensor_new: {
        sensorid: "",
        gain: "",
        channel: "",
        samplingrate: null
      },
      dp_sensor_fields: [
        { key: "port", label: "Port" },
        { key: "sensorid", label: "Sensor ID" },
        {
          key: "gain",
          label: "Gain"
        },
        {
          key: "channel",
          label: "Channel"
        },
        {
          key: "samplingrate",
          label: "Sampling Rate"
        }
      ],
      dp_sensor_list: [],
      max_dp_sensor_list: 12,

      // IR Sensor Details
      ir_ip_addr_new: "",
      ir_ip_addrfields: [
        { key: "IP_address", label: "IR Sensors IP Address List" }
      ],
      ir_ip_addrlist: [],
      max_ir_ip_addrlist: 20,
      ir_sensor_fields: [
        {
          key: "ip4thByte",
          label: "IPv4 (4th Byte)"
        },
        {
          key: "threshold",
          label: "Threshold"
        },
        {
          key: "acqInterval",
          label: "Acquisition Interval (ms)"
        }
      ],
      ir_sensor_new: {
        ip4thByte: null,
        threshold: "",
        acqInterval: null
      },

      version_has_rtviz_support: true,
      sensdata_uploader_enable: true,
      uploader_selection: ["Kinesis", "S3"],
      uploader_choice: "",

      last_modified: "Date Now",
      show: true
    };
  },
  watch: {
    facility_selected: function(newVal, oldVal) {
      if (oldVal === "" || oldVal === undefined || oldVal === newVal) {
        // On fresh load, page does not know the current value ("")
        // so it queries the server. When the server provides it with
        // the current value, there is no need to send a query back.
        return;
      }
      this.saveConfigPageFacilitySelected(newVal);
      this.loadConfigFilesFromS3(newVal);
    },
    "form.firmware.version": function(newVal, oldVal) {
      if (oldVal === newVal) {
        return;
      }
      if (newVal >= "1.0.0") {
        this.version_has_rtviz_support = true;
        this.uploader_choice = this.uploader_selection[0];
      } else {
        this.version_has_rtviz_support = false;
        this.uploader_choice = this.uploader_selection[1];
      }
    },
    sensdata_uploader_enable: function(newVal, oldVal) {
      if (oldVal === newVal) {
        return;
      }

      if (newVal === false) {
        this.form.uploader.enable = false;
        this.form.uploader_kinesis.enable = false;
      } else {
        if (this.uploader_choice === "" || this.uploader_choice === undefined) {
          this.uploader_choice = this.uploader_selection[0];
        }

        if (this.uploader_choice === this.uploader_selection[0]) {
          this.form.uploader_kinesis.enable = true;
          this.form.uploader.enable = false;
        } else {
          this.form.uploader_kinesis.enable = false;
          this.form.uploader.enable = true;
        }
        this.form.uploader.enable = !this.form.uploader_kinesis.enable;
      }
    },
    uploader_choice: function(newVal, oldVal) {
      if (oldVal === newVal) {
        return;
      }
      if (newVal === this.uploader_selection[0]) {
        this.form.uploader_kinesis.enable =
          true && this.sensdata_uploader_enable;
      } else {
        this.form.uploader_kinesis.enable =
          false && this.sensdata_uploader_enable;
      }
      this.form.uploader.enable =
        !this.form.uploader_kinesis.enable && this.sensdata_uploader_enable;
    }
  },
  created: function() {
    // Get a fresh list of facility names from S3 Config bucket
    // every time this template is created
    this.getFacilityNameChoices();
    this.getFirwareVersionChoices();
  },
  methods: {
    // UI Actions
    onSubmit(evt) {
      evt.preventDefault();
      this.saveConfigFilesInS3(this.facility_selected);
    },
    onReset(evt) {
      evt.preventDefault();
      this.loadConfigFilesFromS3(this.facility_selected);
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onAddIrIpAddress(evt) {
      evt.preventDefault();

      if (this.ir_ip_addrlist.length >= this.max_ir_ip_addrlist) {
        alert("Cannot exceed " + this.max_ir_ip_addrlist + " Infrared Sensors");
      } else {
        this.ir_ip_addrlist.push({ IP_address: this.ir_ip_addr_new });
        this.form.ipaddrlist.irsensors.push(this.ir_ip_addr_new);
      }
    },
    onRemoveIrIpAddress(index) {
      this.ir_ip_addrlist.splice(index, 1);
      this.form.ipaddrlist.irsensors.splice(index, 1);
    },
    onAddDopplerSensor(evt) {
      evt.preventDefault();

      if (this.dp_sensor_list.length >= this.max_dp_sensor_list) {
        alert("Cannot exceed " + this.max_dp_sensor_list + " Doppler Sensors");
      } else {
        this.form.doppler.doppler_sensors = this.dopplerAddSensorInConfig(
          this.dp_sensor_new_port,
          this.dp_sensor_new
        );

        var dopplerTableObj = {};
        dopplerTableObj = {
          port: this.dp_sensor_new_port,
          sensorid: this.dp_sensor_new.sensorid,
          gain: this.dp_sensor_new.gain,
          channel: this.dp_sensor_new.channel,
          samplingrate: this.dp_sensor_new.samplingrate
        };
        this.dp_sensor_list.push(dopplerTableObj);
      }
    },
    dopplerAddSensorInConfig(port_number, new_dp_sensor) {
      var temp_obj = Object.assign({}, this.form.doppler.doppler_sensors);
      var dopplerFormObj = {};

      dopplerFormObj[port_number] = {
        sensorid: new_dp_sensor.sensorid,
        gain: new_dp_sensor.gain,
        channel: new_dp_sensor.channel,
        samplingrate: new_dp_sensor.samplingrate
      };
      return Object.assign(temp_obj, dopplerFormObj);
    },
    onRemoveDopplerSensor(row, index) {
      this.dp_sensor_list.splice(index, 1);
      this.dopplerRemoveSensorFromConfig(row.item.port);
    },
    dopplerRemoveSensorFromConfig(port_number) {
      var temp_obj = Object.assign({}, this.form.doppler.doppler_sensors);
      delete temp_obj[port_number];
      this.form.doppler.doppler_sensors = temp_obj;
    },
    onAddInfraredSensor(evt) {
      evt.preventDefault();

      if (this.form.infrared.sensor.list.length >= this.max_ir_ip_addrlist) {
        alert("Cannot exceed " + this.max_ir_ip_addrlist + " Infrared Sensors");
      } else {
        var sensorObj = {
          ip4thByte: this.ir_sensor_new.ip4thByte,
          threshold: this.ir_sensor_new.threshold,
          acqInterval: this.ir_sensor_new.acqInterval
        };

        this.form.infrared.sensor.list.push(sensorObj);
      }
    },
    onRemoveInfraredSensor(index) {
      this.form.infrared.sensor.list.splice(index, 1);
    },

    // Custom Functions
    getFacilityNameChoices() {
      this.getData("facility/names").then(result => {
        if (result.success) {
          this.facility_names = result.facility_name_list.sort(function(a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          });

          // Right after getting the facility name list,
          // get the selected facility from local cache if existing
          this.getFacilityNameSelected();
          this.loadConfigFilesFromS3(this.facility_selected);
        }
      });
    },
    getFirwareVersionChoices() {
      this.getData("facility/fwversion").then(result => {
        if (result.success) {
          this.firmware_versions = result.fwversion_list;
        }
      });
    },
    getFacilityNameSelected() {
      var savedFacilityNameSelected = this.$store.state.savedConfigPageFacility;

      if (savedFacilityNameSelected === "") {
        this.facility_selected = this.facility_names[0];
        this.saveConfigPageFacilitySelected(this.facility_selected);
      } else {
        this.facility_selected = savedFacilityNameSelected;
      }
    },
    saveConfigPageFacilitySelected(facility_name) {
      this.$store.commit("saveConfigPageFacilitySelected", facility_name);
    },
    loadConfigFilesFromS3(facility_name) {
      var api_endpoint = "deviceconfig/" + facility_name;
      this.getData(api_endpoint).then(result => {
        if (result.success) {
          var idx;
          this.form = result.configs_dict;
          this.last_modified = result.last_modified;

          // IP Address List Update
          this.ir_ip_addrlist = [];
          for (idx in this.form.ipaddrlist.irsensors) {
            this.ir_ip_addrlist.push({
              IP_address: this.form.ipaddrlist.irsensors[idx]
            });
          }

          // Doppler Sensors List Update
          this.dp_sensor_list = [];
          for (idx in this.form.doppler.doppler_sensors) {
            var dopplerFormObj = {};
            var dopplerTableObj = {};

            dopplerFormObj = this.form.doppler.doppler_sensors[idx];

            dopplerTableObj = {
              port: idx,
              sensorid: dopplerFormObj.sensorid,
              gain: dopplerFormObj.gain,
              channel: dopplerFormObj.channel,
              samplingrate: dopplerFormObj.samplingrate
            };
            this.dp_sensor_list.push(dopplerTableObj);
          }

          // Sensor Data Uploader
          this.sensdata_uploader_enable =
            this.form.uploader.enable || this.form.uploader_kinesis.enable;
          if (this.form.uploader_kinesis.enable) {
            this.uploader_choice = this.uploader_selection[0];
          } else {
            this.uploader_choice = this.uploader_selection[1];
          }
        }
      });
    },
    saveConfigFilesInS3(facility_name) {
      var api_endpoint = "deviceconfig/" + facility_name;
      var payload = {
        configs_dict: this.form
      };
      this.postData(api_endpoint, payload).then(result => {
        if (result.success) {
          alert(result.Msg);
          this.loadConfigFilesFromS3(facility_name);
        } else {
          if (result.desc === undefined) {
            alert("Failed to send updated device configs to server.");
          } else {
            alert(result.desc);
          }
        }
      });
    }
  }
};
</script>
