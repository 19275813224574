<template>
  <b-form-group label-cols-sm="3" label-align-sm="right">
    <!-- Selection Label -->
    <template #label>{{ label }}</template>

    <!-- Dropdown Form Selection -->
    <b-col sm="5">
      <b-form-select
        :value="value"
        :options="choices"
        :disabled="disabled"
        @input="didSelect"
        required
      ></b-form-select>
    </b-col>
  </b-form-group>
</template>

<style lang="scss" scoped></style>

<script>
export default {
  name: "FormSelect",
  props: {
    label: {
      type: String,
      required: true,
      default: "Change this label"
    },
    value: {
      type: String,
      required: true,
      default: "choice"
    },
    choices: {
      type: Array,
      required: true,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {};
  },
  methods: {
    didSelect(selected) {
      this.$emit("input", selected);
    }
  }
};
</script>
