const AppWordings = {
  en: {
    APP_NAME: "SB-OB Real-Time Visualization",
    APP_VERSION: "v1.0.4",

    TAB_HOME: "Home",
    TAB_SENSOR_DASHBOARD: "Sensor Data Dashboard",
    TAB_CONFIG_SETTINGS: "Configuration Settings",

    LBL_DASH_DP_PANEL_TITLE: "Doppler Sensor Data",
    LBL_DASH_DP_CHART_TITLE: "Doppler Sensor I/Q Data",
    LBL_DASH_DP_CHART_SPS: "Sampling Rate",
    LBL_DASH_DP_CHART_LENGTH: "Sampling Window",
    LBL_DASH_DP_CHART_SENSOR: "Sensor ID",
    LBL_DASH_DP_Y_AXIS: "Value",
    LBL_DASH_DP_X_AXIS: "Time",

    LBL_DASH_IR_PANEL_TITLE: "IR Sensor Data",
    LBL_DASH_IR_CHART_TITLE: "Status",

    LBL_CONF_PREVIEW_HEADER: "Device Configuration File Preview",

    LBL_CONF_SELECT_TITLE: "Select Facility",
    LBL_CONF_FACILITY: "Facility",

    LBL_CONF_VERSION_TITLE: "Firmware Version",
    LBL_CONF_VERSION: "SB-OB Version",

    LBL_CONF_IPADDRLIST_TITLE: "IP Address List",
    LBL_CONF_SUBNET_MASK: "Subnet Mask",
    LBL_CONF_OB_IP_ADDR: "Open Blocks IP Address",
    LBL_CONF_PWRBX_IP_ADDR: "Power Box IP Address",
    LBL_CONF_IR_DEST: "IR Destination IP Address",
    LBL_CONF_IR_SENSOR: "IR Sensors IP Addresses",

    LBL_CONF_DP_PANEL_TITLE: "Doppler Sensors",
    LBL_CONF_DP_DAQ_PORT: "Data Acquisition Port",
    LBL_CONF_DP_DAQ_INTERVAL: "Data Acquisition Interval (Seconds)",
    LBL_CONF_DP_GETSTATE_INTERVAL: "Periodic Get State Interval (Seconds)",
    LBL_CONF_DP_SENDINFO_ENABLE: "Send Info from Power Box Enable",
    LBL_CONF_DP_SENDINFO_PORT: "Send Info Port",
    LBL_CONF_DP_SETCLOCK_ENABLE: "Enable Clock Update",
    LBL_CONF_DP_SETCLOCK_INTERVAL: "Clock Update Interval (Hours)",
    LBL_CONF_DP_PWRBX_PORT: "Power Box Port",
    LBL_CONF_DP_PWRBX_ID: "Power Box ID",
    LBL_CONF_PBCAL_MODE: "Mode",
    LBL_CONF_PBCAL_RANGE: "Range",
    LBL_CONF_PBCAL_COUNT: "Count",
    LBL_CONF_PBCAL_INT: "Int",
    LBL_CONF_PBCAL_TIMER: "Timer",

    LBL_CONF_IR_PANEL_TITLE: "Infrared Sensors",
    LBL_CONF_IR_TCP_PORT: "IR Sensor TCP Port",

    LBL_CONF_UPLOADER_TITLE: "Sensor Data Uploader",
    LBL_CONF_UPLOAD_PATH: "Upload Path",
    LBL_CONF_UPLOAD_INT: "Upload Interval (Seconds)",

    LBL_CONF_UPDATER_TITLE: "Device Config Updater",
    LBL_CONF_UPDATE_INT: "Update Interval (Seconds)",

    LBL_COMMON_ENABLE: "Enable",
    LBL_COMMON_MODE_SELECT: "Mode",

    INF_DEFAULT: "Please wait.",
    INF_LOADING: "Loading...",
    INF_APPLYING_CONFIG:
      "Please wait while config settings are applied to the sensor board",

    ERR_DEFAULT: "The server could not process your request."
  }
};

AppWordings.install = function(Vue, options) {
  Vue.prototype.$getWording = (key, arg = null) => {
    let template = AppWordings[options.lang][key];
    if (arg == null) {
      return template;
    } else {
      return template.replace("___", arg);
    }
  };
};

export default AppWordings;
